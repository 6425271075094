import HENGE from "@henge-inc/global-types";

export const GAIA_MEMBER_ROLE = {
  GUEST: "GUEST",
  MEMBER: "MEMBER",
  OWNER: "OWNER",
} as const;
export type GaiaMemberRoleType = HENGE.ValuesTypeFromObject<
  typeof GAIA_MEMBER_ROLE
>;

export const ROSETTA_CODE = {
  DOCUMENT: "DOCUMENT",
  CAMERA: "CAMERA",
  LIGHT: "LIGHT",
  IDEATION: "IDEATION",
  MATERIAL: "MATERIAL",
} as const;
export type RosettaCodeType = HENGE.ValuesTypeFromObject<typeof ROSETTA_CODE>;

export const ROSETTA_RELEASE_STATUS = {
  INTERNAL_REVIEW: "INTERNAL_REVIEW",
  COMING_SOON: "COMING_SOON",
  RELEASED: "RELEASED",
  NOT_IN_SUPPORT: "NOT_IN_SUPPORT",
} as const;
export type RosettaReleaseStatusType = HENGE.ValuesTypeFromObject<
  typeof ROSETTA_RELEASE_STATUS
>;

export const VAULT_MEMBER_ROLE = {
  OWNER: "OWNER",
  EDITOR: "EDITOR",
  VIEWER: "VIEWER",
} as const;
export type VaultMemberRoleType = HENGE.ValuesTypeFromObject<
  typeof VAULT_MEMBER_ROLE
>;

export const VAULT_MEMBER_STATUS = {
  JOIN: "JOIN",
  REQ_JOIN: "REQ_JOIN",
  REJECT_REQ_JOIN: "REJECT_REQ_JOIN",
  BLOCK_REQ_JOIN: "BLOCK_REQ_JOIN",
  REQ_INVITE: "REQ_INVITE",
  REJECT_REQ_INVITE: "REJECT_REQ_INVITE",
  BLOCK_REQ_INVITE: "BLOCK_REQ_INVITE",
  LEAVE: "LEAVE",
} as const;
export type VaultMemberStatusType = HENGE.ValuesTypeFromObject<
  typeof VAULT_MEMBER_STATUS
>;

export const VAULT_OBJECT_TYPE = {
  OBJECT: "OBJECT",
  MATERIAL: "MATERIAL",
  PBR: "PBR",
} as const;
export type VaultObjectType = HENGE.ValuesTypeFromObject<
  typeof VAULT_OBJECT_TYPE
>;

export const VAULT_OBJECT_TYPE_FILTER = {
  All: "All",
  Geometry: "Geometry",
  Material: "Material",
  Physics: "Physics",
} as const;
export type VaultObjectTypeFilter = HENGE.ValuesTypeFromObject<
  typeof VAULT_OBJECT_TYPE_FILTER
>;

export const VAULT_ACCESS = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
} as const;
export type VaultAccess = HENGE.ValuesTypeFromObject<typeof VAULT_ACCESS>;

export const VAULT_UPLOAD_POLICY = {
  ANYONE: "ANYONE",
  ONLY_ME: "ONLY_ME",
} as const;
export type VaultUploadPolicy = HENGE.ValuesTypeFromObject<
  typeof VAULT_UPLOAD_POLICY
>;

export const SKETCHFAB_MIGRATION_TASK_STATUS = {
  INITIALIZED: "INITIALIZED",
  IN_PROCESS: "IN_PROCESS",
  FINISHED: "FINISHED",
} as const;
export type SketchfabMigrationTaskStatus = HENGE.ValuesTypeFromObject<
  typeof SKETCHFAB_MIGRATION_TASK_STATUS
>;
