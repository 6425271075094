import { GaiaData, HengeData } from "@/types/data-types";
import { DEFAULT_GAIA_GRID_SIZE } from "@/utils/gaiaUtil";
import { Size } from "@react-three/fiber";
import CameraControlsStdlib from "camera-controls";
import * as THREE from "three";
import { HengeCameraControls } from "@/utils/threeUtil";

export const GAIA_BACKGROUND_COLOR = "#f8f8f8";
export const GAIA_FOV = 28;
export const DEFAULT_FOV_1_OVER_TANGENT =
  1 / Math.tan((GAIA_FOV * Math.PI) / 360);
export const GAIA_ZOOM_CONSTANT = 0.75;
export const GAIA_EDIT_MODE_ZOOM_CONSTANT = 0.88;
export const HENGE_PERSPECTIVE_ZOOM_CONSTANT = 0.01;
export const HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT = 8;
export const HENGE_FOCAL_OFFSET_CONSTANT = 0.0018;
export const HENGE_PERSPECTIVE_MAX_DISTANCE_CONSTANT = 18;
export const HENGE_ORTHOGRAPHIC_MAX_DISTANCE_CONSTANT = 9000;
export const CAPTURE_ZOOM_CONSTANT = 8;
export const GAIA_CAMERA_POSITION = new THREE.Vector3(10, 10, 10);
export const DEFAULT_COS_ZX =
  GAIA_CAMERA_POSITION.z /
  Math.pow(
    Math.pow(GAIA_CAMERA_POSITION.x, 2) + Math.pow(GAIA_CAMERA_POSITION.z, 2),
    0.5,
  );
export const DEFAULT_SIN_Y =
  Math.pow(
    Math.pow(GAIA_CAMERA_POSITION.x, 2) + Math.pow(GAIA_CAMERA_POSITION.z, 2),
    0.5,
  ) /
  Math.pow(
    Math.pow(GAIA_CAMERA_POSITION.x, 2) +
      Math.pow(GAIA_CAMERA_POSITION.z, 2) +
      Math.pow(GAIA_CAMERA_POSITION.y, 2),
    0.5,
  );
export const DEFAULT_AZIMUTH_ANGLE = Math.acos(DEFAULT_COS_ZX);
export const DEFAULT_POLAR_ANGLE = Math.asin(DEFAULT_SIN_Y);
// export const GAIA_CAMERA_TARGET = new THREE.Vector3(0, DEFAULT_SIN_Y, 0);
export const GAIA_CAMERA_TARGET = new THREE.Vector3(0, DEFAULT_SIN_Y, 0);
export const HENGE_PERSPECTIVE_CAMERA_POSITION = new THREE.Vector3(2, 2, 2);
export const ZERO_POLAR_ANGLE_TOLERANCE_DEGREE = 0.1;
export const SMOOTH_TIME = 0.4;
export const DRAGGING_SMOOTH_TIME = 0.2;

export function calcZoom(
  cameraControls: HengeCameraControls,
  gaiaData: GaiaData | null,
  size: Size,
  isHenge: boolean = false,
): number {
  const gridLength = gaiaData?.grid?.length || DEFAULT_GAIA_GRID_SIZE;

  // const azimuthAngle = isDefault
  //   ? DEFAULT_AZIMUTH_ANGLE
  //   : cameraControls.azimuthAngle;
  // const polarAngle = isDefault
  //   ? DEFAULT_POLAR_ANGLE
  //   : cameraControls.polarAngle;

  const gaiaWidth = gridLength * Math.pow(2, 0.5);
  // const gaiaWidth =
  //   gridLength *
  //   (Math.abs(Math.cos(DEFAULT_AZIMUTH_ANGLE)) +
  //     Math.abs(Math.cos(DEFAULT_AZIMUTH_ANGLE - Math.PI / 2)));
  const gaiaHeight = gaiaWidth * Math.cos(DEFAULT_POLAR_ANGLE);
  // const gaiaHeight =
  //   gridLength * Math.sin(DEFAULT_POLAR_ANGLE) +
  //   gridLength * Math.cos(DEFAULT_POLAR_ANGLE);

  let reZoom =
    GAIA_ZOOM_CONSTANT *
    Math.min(size.width / gaiaWidth, size.height / gaiaHeight);
  if (isHenge) {
    reZoom =
      GAIA_ZOOM_CONSTANT *
      Math.min(size.width / gridLength, size.height / gridLength);
  }
  // const reZoom = Math.min(
  //   (GAIA_ZOOM_BY_WIDTH_CONSTANT * size.width) / gaiaWidth,
  //   (GAIA_ZOOM_BY_HEIGHT_CONSTANT * (size.height + 2048)) / gaiaHeight,
  // );

  return reZoom;
}

export async function initiateGaiaView(
  cameraControls: HengeCameraControls,
): Promise<void[]> {
  cameraControls.minPolarAngle = DEFAULT_POLAR_ANGLE;
  cameraControls.maxPolarAngle = DEFAULT_POLAR_ANGLE;
  cameraControls.minAzimuthAngle = DEFAULT_AZIMUTH_ANGLE;
  cameraControls.maxAzimuthAngle = DEFAULT_AZIMUTH_ANGLE;

  cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.NONE;
  cameraControls.touches.one = CameraControlsStdlib.ACTION.NONE;
  cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.NONE;
  cameraControls.touches.three = CameraControlsStdlib.ACTION.NONE;
  cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.NONE;
  cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.NONE;
  cameraControls.touches.two = CameraControlsStdlib.ACTION.NONE;

  cameraControls.smoothTime = SMOOTH_TIME;
  cameraControls.draggingSmoothTime = DRAGGING_SMOOTH_TIME;

  cameraControls.dollySpeed = 2.0;

  (cameraControls.camera as THREE.PerspectiveCamera).fov = GAIA_FOV;
  cameraControls.camera.updateProjectionMatrix();

  //================================================================

  const promises: Promise<void>[] = [];

  //

  promises.push(
    cameraControls.setLookAt(
      GAIA_CAMERA_POSITION.x,
      GAIA_CAMERA_POSITION.y,
      GAIA_CAMERA_POSITION.z,
      GAIA_CAMERA_TARGET.x,
      GAIA_CAMERA_TARGET.y,
      GAIA_CAMERA_TARGET.z,
      true,
    ),
  );

  promises.push(cameraControls.setFocalOffset(0, 0, 0, true));

  promises.push(
    cameraControls.rotateTo(DEFAULT_AZIMUTH_ANGLE, DEFAULT_POLAR_ANGLE, true),
  );

  return Promise.all(promises);
}

export async function initiateHengeView(
  cameraControls: HengeCameraControls,
  hengeData: HengeData,
  hengeObject: THREE.Object3D,
): Promise<void[]> {
  cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.ROTATE;
  cameraControls.touches.one = CameraControlsStdlib.ACTION.TOUCH_ROTATE;
  cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.OFFSET;
  cameraControls.touches.three = CameraControlsStdlib.ACTION.TOUCH_OFFSET;
  // cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.DOLLY;
  cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.ZOOM;
  // cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.DOLLY;
  cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.touches.two = CameraControlsStdlib.ACTION.TOUCH_DOLLY;
  cameraControls.touches.two = CameraControlsStdlib.ACTION.TOUCH_ZOOM;
  // cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.touches.one = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.touches.three = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.NONE;
  // cameraControls.touches.two = CameraControlsStdlib.ACTION.NONE;

  cameraControls.smoothTime = SMOOTH_TIME;
  cameraControls.draggingSmoothTime = DRAGGING_SMOOTH_TIME;

  cameraControls.dollySpeed = 2.0;

  cameraControls.minPolarAngle = 0;
  cameraControls.maxPolarAngle = Math.PI;
  cameraControls.minAzimuthAngle = -Infinity;
  cameraControls.maxAzimuthAngle = Infinity;

  // changeCameraType(hengeData.cameraType);

  cameraControls.maxDistance =
    hengeData.cameraType === "Perspective"
      ? HENGE_PERSPECTIVE_MAX_DISTANCE_CONSTANT
      : HENGE_ORTHOGRAPHIC_MAX_DISTANCE_CONSTANT;

  (cameraControls.camera as THREE.PerspectiveCamera).fov =
    hengeData.cameraType === "Perspective" ? hengeData.fov : GAIA_FOV;
  cameraControls.camera.updateProjectionMatrix();
  // changeFov(
  //   hengeData.cameraType === "Perspective" ? hengeData.fov : GAIA_FOV,
  // );

  //================================================================

  const promises: Promise<void>[] = [];

  //

  const boundingBox: THREE.Box3 = hengeObject.userData.boundingBox;

  // const height = boundingBox.max.y - boundingBox.min.y;
  // const width = boundingBox.max.x - boundingBox.min.x;
  // const distanceToFit =
  //   cameraControls.getDistanceToFitSphere(HENGE_SCALE_CONSTANT) *
  //   (height ** 0.75 / width ** 0.75);

  const target = {
    x: hengeData.position.x,
    y:
      hengeData.position.y +
      (hengeObject.userData.autoScale *
        (boundingBox.max.y - boundingBox.min.y)) /
        2,
    z: hengeData.position.z,
  };

  const positionToTarget =
    hengeData.cameraType === "Perspective"
      ? HENGE_PERSPECTIVE_CAMERA_POSITION
      : GAIA_CAMERA_POSITION;

  //

  if (hengeData.cameraType === "Perspective") {
    const newFov1OverTangent = 1 / Math.tan((hengeData.fov * Math.PI) / 360);
    const tangentRatio = newFov1OverTangent / DEFAULT_FOV_1_OVER_TANGENT;

    const newPosition = positionToTarget.clone().multiplyScalar(tangentRatio);

    promises.push(
      cameraControls.setLookAt(
        newPosition.x + target.x,
        newPosition.y + target.y,
        newPosition.z + target.z,
        target.x,
        target.y,
        target.z,
        false,
      ),
    );
  } else {
    promises.push(
      cameraControls.setLookAt(
        positionToTarget.x + target.x,
        positionToTarget.y + target.y,
        positionToTarget.z + target.z,
        target.x,
        target.y,
        target.z,
        false,
      ),
    );
  }

  // cameraControls.distanceToHenge = distanceToFit;
  // promises.push(cameraControls.dollyTo(distanceToFit, true));

  promises.push(cameraControls.setFocalOffset(0, 0, 0, true));

  promises.push(
    cameraControls.rotateTo(DEFAULT_AZIMUTH_ANGLE, DEFAULT_POLAR_ANGLE, true),
    // cameraControls.rotateTo(0, Math.PI / 2, true),
  );

  return Promise.all(promises);
}

export async function initiateHengeOrthographicView(
  scene: THREE.Scene,
  cameraControls: HengeCameraControls,
  gaiaData: GaiaData,
  size: Size,
  hengeData: HengeData,
  hengeObject: THREE.Object3D,
  zoomMultiplier: number,
): Promise<void> {
  cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.ROTATE;
  cameraControls.touches.one = CameraControlsStdlib.ACTION.TOUCH_ROTATE;
  cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.OFFSET;
  cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.NONE;
  cameraControls.touches.three = CameraControlsStdlib.ACTION.TOUCH_OFFSET;
  cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.DOLLY;
  cameraControls.touches.two = CameraControlsStdlib.ACTION.TOUCH_DOLLY;

  cameraControls.smoothTime = SMOOTH_TIME;
  cameraControls.draggingSmoothTime = DRAGGING_SMOOTH_TIME;

  cameraControls.minPolarAngle = 0;
  cameraControls.maxPolarAngle = Math.PI;
  cameraControls.minAzimuthAngle = -Infinity;
  cameraControls.maxAzimuthAngle = Infinity;

  cameraControls.maxDistance = HENGE_ORTHOGRAPHIC_MAX_DISTANCE_CONSTANT;

  //

  (cameraControls.camera as THREE.PerspectiveCamera).fov = GAIA_FOV;
  cameraControls.camera.updateProjectionMatrix();

  cameraControls.setFocalOffset(0, 0, 0, true);

  const gaiaZoom = calcZoom(cameraControls, gaiaData, size);

  const hengeZoom =
    zoomMultiplier * gaiaZoom * HENGE_ORTHOGRAPHIC_ZOOM_CONSTANT;
  cameraControls.zoomTo(hengeZoom, true);

  const boundingBox = hengeObject.userData.boundingBox;

  const target = {
    x: hengeData.position.x,
    y:
      hengeData.position.y +
      (hengeObject.userData.autoScale *
        (boundingBox.max.y - boundingBox.min.y)) /
        2,
    z: hengeData.position.z,
  };

  const position = GAIA_CAMERA_POSITION;

  cameraControls.setLookAt(
    position.x + target.x,
    position.y + target.y,
    position.z + target.z,
    target.x,
    target.y,
    target.z,
    true,
  );

  await cameraControls.rotateTo(
    DEFAULT_AZIMUTH_ANGLE,
    DEFAULT_POLAR_ANGLE,
    true,
  );
}

export async function initiateHengePerspectiveView(
  scene: THREE.Scene,
  cameraControls: HengeCameraControls,
  gaiaData: GaiaData,
  size: Size,
  hengeData: HengeData,
  hengeObject: THREE.Object3D,
  zoomMultiplier: number,
  fov: number,
): Promise<void> {
  cameraControls.mouseButtons.left = CameraControlsStdlib.ACTION.ROTATE;
  cameraControls.touches.one = CameraControlsStdlib.ACTION.TOUCH_ROTATE;
  cameraControls.mouseButtons.right = CameraControlsStdlib.ACTION.OFFSET;
  cameraControls.mouseButtons.middle = CameraControlsStdlib.ACTION.NONE;
  cameraControls.touches.three = CameraControlsStdlib.ACTION.TOUCH_OFFSET;
  cameraControls.mouseButtons.wheel = CameraControlsStdlib.ACTION.DOLLY;
  cameraControls.touches.two = CameraControlsStdlib.ACTION.TOUCH_DOLLY;

  cameraControls.smoothTime = SMOOTH_TIME;
  cameraControls.draggingSmoothTime = DRAGGING_SMOOTH_TIME;

  cameraControls.minPolarAngle = 0;
  cameraControls.maxPolarAngle = Math.PI;
  cameraControls.minAzimuthAngle = -Infinity;
  cameraControls.maxAzimuthAngle = Infinity;

  cameraControls.maxDistance = HENGE_PERSPECTIVE_MAX_DISTANCE_CONSTANT;

  //

  cameraControls.setFocalOffset(0, 0, 0, true);

  const gaiaZoom = calcZoom(cameraControls, gaiaData, size);

  const hengeZoom = zoomMultiplier * gaiaZoom * HENGE_PERSPECTIVE_ZOOM_CONSTANT;
  cameraControls.zoomTo(hengeZoom, true);

  const boundingBox = hengeObject.userData.boundingBox;

  const target = {
    x: hengeData.position.x,
    y:
      hengeData.position.y +
      (hengeObject.userData.autoScale *
        (boundingBox.max.y - boundingBox.min.y)) /
        2,
    z: hengeData.position.z,
  };

  const position = HENGE_PERSPECTIVE_CAMERA_POSITION;

  (cameraControls.camera as THREE.PerspectiveCamera).fov = fov;
  cameraControls.camera.updateProjectionMatrix();

  const newDistance = 1 / Math.tan((fov * Math.PI) / 360);
  const distanceRatio = newDistance / DEFAULT_FOV_1_OVER_TANGENT;

  cameraControls.maxDistance =
    HENGE_PERSPECTIVE_MAX_DISTANCE_CONSTANT *
    hengeObject.scale.y *
    (newDistance / DEFAULT_FOV_1_OVER_TANGENT);

  const newPosition = position.clone().multiplyScalar(distanceRatio);

  cameraControls.setLookAt(
    newPosition.x + target.x,
    newPosition.y + target.y,
    newPosition.z + target.z,
    target.x,
    target.y,
    target.z,
    true,
  );

  await cameraControls.rotateTo(
    DEFAULT_AZIMUTH_ANGLE,
    DEFAULT_POLAR_ANGLE,
    true,
  );
}
