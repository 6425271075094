const secret = process.env.OAUTH_STATE_TOKEN_SECRET;

export async function createJWT(payload: string): Promise<string> {
  if (!secret) {
    throw new Error();
  }

  const encoder = new TextEncoder();
  const keyData = encoder.encode(secret);
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"],
  );

  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const base64UrlEncode = (data: string) =>
    btoa(data).replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

  const headerBase64 = base64UrlEncode(JSON.stringify(header));
  const payloadBase64 = base64UrlEncode(payload);

  const data = `${headerBase64}.${payloadBase64}`;
  const signature = await crypto.subtle.sign(
    "HMAC",
    cryptoKey,
    encoder.encode(data),
  );
  const signatureBase64 = btoa(
    String.fromCharCode(...new Uint8Array(signature)),
  )
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");

  return `${data}.${signatureBase64}`;
}

export async function verifyJWT(token: string): Promise<object | null> {
  if (!secret) {
    throw new Error();
  }

  const [headerBase64, payloadBase64, signatureBase64] = token.split(".");

  const encoder = new TextEncoder();
  const keyData = encoder.encode(secret);
  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["verify"],
  );

  const data = `${headerBase64}.${payloadBase64}`;
  const signature = Uint8Array.from(
    atob(signatureBase64.replace(/-/g, "+").replace(/_/g, "/")),
    (c) => c.charCodeAt(0),
  );

  const isValid = await crypto.subtle.verify(
    "HMAC",
    cryptoKey,
    signature,
    encoder.encode(data),
  );

  if (!isValid) {
    return null;
  }

  return JSON.parse(atob(payloadBase64.replace(/-/g, "+").replace(/_/g, "/")));
}
