import { Message } from "stompjs";
import { MemberAck } from "@/websocket/enums";
import { HengeAuthTokens } from "@/classes/auth/Auth";
import useMemberStore from "@/stores/zustand/member/useMemberStore";
import { Member } from "@/classes/member/Member";

export const memberSocketMessageHandler = (
  member: Member,
  authTokens: HengeAuthTokens | undefined | null,
  dest: string,
  message: Message,
) => {
  const body = JSON.parse(message.body);

  if ((message.headers as { destination: string }).destination !== dest) return;
  if (!body.ack) return;

  if (body.ack === MemberAck.INITIATED) {
    const socketSessionId: string = body.data?.sessionId;
    if (!socketSessionId) return;
  } else if (body.ack === MemberAck.REFRESH_NOTIFICATION) {
    useMemberStore
      .getState()
      .memberStoreActions.refreshNotificationList(authTokens);
  }
};
