import * as THREE from "three";
import { create, StoreApi, UseBoundStore } from "zustand";
import useGaiaStoreContainer, {
  useCurrentGaiaStore,
} from "@/engine/viewer/core/useGaiaStoreContainer";
import { GaiaParts, updateHengeIdOnGridMap } from "@/utils/gaiaUtil";
import {
  SharedViewerStore,
  ViewerState,
} from "@/engine/viewer/core/useViewerStore";
import {
  GaiaData,
  HengeData,
  LibraryData,
  RosettaCellMap,
} from "@/types/data-types";
import {
  createGaiaMemberSessionContainer,
  GaiaMemberSessionContainerInterface,
} from "@/stores/zustand/gaia-member/useGaiaMemberSessionContainer";
import {
  fetchDeleteHengeAPI,
  fetchGetGaiaAPI,
  fetchGetHengeListAPI,
} from "@/utils/api/fetchWithHengeAuthAPIs";
import { HengeAuthTokens } from "@/classes/auth/Auth";
import { ApiResultEnum } from "@/types/api-result";
import { produce } from "immer";
import _ from "lodash";
import { UploadingHengeType } from "@/utils/hengeUtil";

export interface GaiaStoreInterface {
  gaiaMemberSessionStore: UseBoundStore<
    StoreApi<GaiaMemberSessionContainerInterface>
  >;
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>;
  gaiaObject: THREE.Group;
  uploadUnitVisible: boolean;
  gaiaObjectHoverSpacerIndices: [row: number, column: number] | null;
  gaiaObjectUploadingHengeIndices: [row: number, column: number] | null;
  trashCanObject: THREE.Group;
  hengeContainer: THREE.Group;
  orbitObject: THREE.Group;
  /** Could be null */
  gaiaData: GaiaData;
  hengeDataList: HengeData[];
  hoverHengeId: number | null;
  editingHengeId: number | null;
  uploadingHengeList: UploadingHengeType[];
  hengeUploadMethod:
    | "Drag & Drop"
    | "File Dialog"
    | "Generate"
    | "Library"
    | null;
  generatingPanelOpen: boolean;
  referenceImageFileGenerateHenge: File | null;
  generatingHengeUploadObject: boolean;
  draggingGaiaLibraryObject: LibraryData | null;
  triggerOnClickGaiaLibraryItem: boolean;
  pinnedCellMap: RosettaCellMap;
  gaiaStoreActions: {
    setGaiaObject: (object: THREE.Group) => void;
    updateUploadUnitVisible: (uploadUnitVisible: boolean) => void;
    updateGaiaObjectHoverSpacerIndices: (
      gaiaObjectHoverSpacerIndices: [row: number, column: number] | null,
    ) => void;
    updateGaiaObjectUploadingHengeIndices: (
      gaiaObjectUploadingHengeIndices: [row: number, column: number] | null,
    ) => void;
    setTrashCanObject: (object: THREE.Group) => void;
    setHengeContainer: (object: THREE.Group) => void;
    setOrbitObject: (object: THREE.Group) => void;
    refreshGaiaData: (authTokens: HengeAuthTokens | undefined | null) => void;
    refreshHengeDataList: (
      authTokens: HengeAuthTokens | undefined | null,
    ) => void;
    updateHengeData: (updatedHengeData: HengeData) => void;
    getHengeData: (id: number | null) => HengeData | null;
    deleteHengeData: (
      id: number,
      authTokens: HengeAuthTokens | undefined | null,
    ) => ReturnType<typeof fetchDeleteHengeAPI>;
    changeHoverHengeId: (hoverHengeId: number | null) => void;
    changeEditingHengeId: (editingHengeId: number | null) => void;
    pushUploadingHenge: (uploadingHenge: UploadingHengeType) => void;
    removeUploadingHenge: (uploadingHenge: UploadingHengeType) => void;
    updateHengeUploadMethod: (
      hengeUploadMethod:
        | "Drag & Drop"
        | "File Dialog"
        | "Generate"
        | "Library"
        | null,
    ) => void;
    updateGeneratingPanelOpen: (generatingPanelOpen: boolean) => void;
    updateReferenceImageFileGenerateHenge: (
      referenceImageFileGenerateHenge: File | null,
    ) => void;
    updateGeneratingHengeUploadObject: (
      generatingHengeUploadObject: boolean,
    ) => void;
    updateDraggingGaiaLibraryObject: (
      draggingGaiaLibraryObject: LibraryData | null,
    ) => void;
    updateTriggerOnClickGaiaLibraryItem: (
      triggerOnClickGaiaLibraryItem: boolean,
    ) => void;
    setPinnedCellMap: (
      callbackFn: (pinnedCellMap: RosettaCellMap) => RosettaCellMap,
    ) => void;
  };
}

export const createEmptyGaiaStore = (
  gaiaLinkedViewerStore: UseBoundStore<StoreApi<ViewerState>>,
) =>
  create<GaiaStoreInterface>((set, get) => ({
    gaiaMemberSessionStore: createGaiaMemberSessionContainer(),
    gaiaLinkedViewerStore: gaiaLinkedViewerStore,
    gaiaObject: null as unknown as THREE.Group,
    uploadUnitVisible: false,
    gaiaObjectHoverSpacerIndices: null,
    gaiaObjectUploadingHengeIndices: null,
    trashCanObject: null as unknown as THREE.Group,
    hengeContainer: null as unknown as THREE.Group,
    orbitObject: null as unknown as THREE.Group,
    gaiaData: null as unknown as GaiaData,
    hengeDataList: null as unknown as HengeData[],
    hoverHengeId: null,
    editingHengeId: null,
    uploadingHengeList: [],
    hengeUploadMethod: null,
    generatingPanelOpen: false,
    referenceImageFileGenerateHenge: null,
    generatingHengeUploadObject: false,
    draggingGaiaLibraryObject: null,
    pinnedCellMap: {} as RosettaCellMap,
    triggerOnClickGaiaLibraryItem: false,
    gaiaStoreActions: {
      setGaiaObject: (object) => set(() => ({ gaiaObject: object })),
      updateUploadUnitVisible: (uploadUnitVisible) =>
        set(() => ({ uploadUnitVisible })),
      updateGaiaObjectHoverSpacerIndices: (gaiaObjectHoverSpacerIndices) =>
        set(() => ({ gaiaObjectHoverSpacerIndices })),
      updateGaiaObjectUploadingHengeIndices: (
        gaiaObjectUploadingHengeIndices,
      ) => set(() => ({ gaiaObjectUploadingHengeIndices })),
      setTrashCanObject: (object) => set(() => ({ trashCanObject: object })),
      setHengeContainer: (object) => set(() => ({ hengeContainer: object })),
      setOrbitObject: (object) => set(() => ({ orbitObject: object })),
      refreshGaiaData: async (authTokens) => {
        if (!authTokens) return;

        const gaiaDataRes = await fetchGetGaiaAPI(
          { queryParams: { gaiaId: get().gaiaData.id } },
          authTokens,
        );

        if (
          gaiaDataRes.code === ApiResultEnum.OK.code &&
          "data" in gaiaDataRes
        ) {
          set(() => ({ gaiaData: gaiaDataRes.data }));
        }
      },
      refreshHengeDataList: async (authTokens) => {
        if (!authTokens) return;

        const hengeDataListRes = await fetchGetHengeListAPI(
          { queryParams: { gaiaId: get().gaiaData.id } },
          authTokens,
        );

        if (
          hengeDataListRes.code === ApiResultEnum.OK.code &&
          "data" in hengeDataListRes
        ) {
          set(() => ({
            hengeDataList: hengeDataListRes.data,
          }));
        }
      },
      updateHengeData: (updatedHengeData) => {
        set((state) => ({
          gaiaData: {
            ...state.gaiaData,
            grid: produce(state.gaiaData.grid, (draft) => {
              updateHengeIdOnGridMap(
                draft,
                updatedHengeData.id,
                updatedHengeData.position,
                updatedHengeData.size,
              );
            }),
          },
          hengeDataList: state.hengeDataList.map((hengeData) =>
            hengeData.id === updatedHengeData.id ? updatedHengeData : hengeData,
          ),
        }));
      },
      getHengeData: (id) =>
        get().hengeDataList.find((_hengeData) => _hengeData.id === id) ?? null,
      deleteHengeData: (id, authTokens) => {
        return fetchDeleteHengeAPI(
          { queryParams: { hengeId: id } },
          authTokens,
        );
      },
      changeHoverHengeId: (hoverHengeId) => set(() => ({ hoverHengeId })),
      changeEditingHengeId: (editingHengeId) => set(() => ({ editingHengeId })),
      pushUploadingHenge: (uploadingHenge) =>
        set((state) => ({
          uploadingHengeList: produce(state.uploadingHengeList, (draft) => {
            draft.push(uploadingHenge);
          }),
        })),
      removeUploadingHenge: (uploadingHenge) =>
        set((state) => ({
          uploadingHengeList: produce(state.uploadingHengeList, (draft) => {
            const findIndex = draft.findIndex((henge) =>
              _.isEqual(henge, uploadingHenge),
            );

            if (findIndex > -1) {
              draft.splice(findIndex, 1);
            }
          }),
        })),
      updateHengeUploadMethod: (hengeUploadMethod) =>
        set(() => ({ hengeUploadMethod })),
      updateGeneratingPanelOpen: (generatingPanelOpen) =>
        set({ generatingPanelOpen }),
      updateReferenceImageFileGenerateHenge: (
        referenceImageFileGenerateHenge,
      ) => set({ referenceImageFileGenerateHenge }),
      updateGeneratingHengeUploadObject: (generatingHengeUploadObject) =>
        set({ generatingHengeUploadObject }),
      updateDraggingGaiaLibraryObject: (draggingGaiaLibraryObject) =>
        set({ draggingGaiaLibraryObject }),
      updateTriggerOnClickGaiaLibraryItem: (triggerOnClickGaiaLibraryItem) =>
        set({ triggerOnClickGaiaLibraryItem }),
      setPinnedCellMap: (callbackFn) => {
        const prevPinMap = get().pinnedCellMap;

        set(() => ({
          pinnedCellMap: callbackFn(prevPinMap),
        }));
      },
    },
  }));

/**
 * GaiaStore 생성 및 동록
 */
export function createGaiaStore(
  hdri: THREE.DataTexture | null,
  parts: GaiaParts,
  initialGaiaData: GaiaData,
  initialHengeDataList: HengeData[],
): UseBoundStore<StoreApi<GaiaStoreInterface>> {
  const { getState } = useGaiaStoreContainer;
  const { gaiaStoreContainerActions } = getState();
  const { hasGaiaStore, getGaiaStore, addGaiaStore } =
    gaiaStoreContainerActions;

  /**
   * 기존에 등록된 GaiaStore인지 확인
   */
  const existingGaiaStore = getGaiaStore(initialGaiaData.id);
  if (hasGaiaStore(initialGaiaData.id) && existingGaiaStore) {
    return existingGaiaStore;
  }

  const newGaiaStore = createEmptyGaiaStore(SharedViewerStore);
  newGaiaStore.setState({
    gaiaData: initialGaiaData,
    hengeDataList: initialHengeDataList,
  });

  /**
   * 새로운 GaiaStore 등록
   */
  addGaiaStore(initialGaiaData.id, newGaiaStore);

  return newGaiaStore;
}

export function useGaiaStore<T = GaiaStoreInterface>(
  selector: (state: GaiaStoreInterface) => T = (state) => state as unknown as T,
) {
  const gaiaStore = useCurrentGaiaStore();

  if (!gaiaStore) throw new Error("Gaia has not been loaded.");

  return gaiaStore(selector);
}
