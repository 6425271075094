import {
  AuthMethod,
  AuthProvider,
  HengeShareScope,
} from "@/types/common-types";
import { VaultObjectType, VaultAccess, VaultUploadPolicy } from "@/types/enums";

export const GTM_CLASS = {
  Auth: {
    log_in: "log_in",
    log_out: "log_out",
    sign_up: "sign_up",
    waitlist_closed_beta: "waitlist_closed_beta",
    resend_verification_email: "resend_verification_email",
  },
  Gaia: {
    create_gaia: "create_gaia",
    delete_gaia: "delete_gaia",
    gaia_update_name: "gaia_update_name",
    gaia_update_emoji: "gaia_update_emoji",
    route_to_gaia_from_another_gaia: "route_to_gaia_from_another_gaia",
    route_to_gaia_from_henge: "route_to_gaia_from_henge",
    route_to_gaia_from_vault: "route_to_gaia_from_vault",
  },
  Henge: {
    create_henge: "create_henge",
    delete_henge: "delete_henge",
    henge_object: "henge_object",
    henge_update_name: "henge_update_name",
    henge_update_data: "henge_update_data",
    henge_update_transformations: "henge_update_transformations",
  },
  Library: {
    open_library: "open_library",
    select_material: "select_material",
  },
  Rosetta: {
    rosetta_window: "rosetta_window",
    rosetta_update_data: "rosetta_update_data",
  },
  Tuning_Shop: {
    open_tuning_shop: "open_tuning_shop",
    tuning_shop_window: "tuning_shop_window",
    tuning_shop_tab: "tuning_shop_tab",
    add_rosetta: "add_rosetta",
    remove_rosetta: "remove_rosetta",
  },
  Share: {
    open_share: "open_share",
    share_invite_gaia_member: "share_invite_gaia_member",
    share_update_gaia_member_role: "share_update_gaia_member_role",
    share_drop_gaia_member: "share_drop_gaia_member",
    share_copy_link: "share_copy_link",
    share_copy_embed: "share_copy_embed",
    change_henge_scope: "change_henge_scope",
  },
  Preferences: {
    open_preferences: "open_preferences",
    preferences_navigation: "preferences_navigation",
    theme_day: "theme_day",
    theme_night: "theme_night",
    theme_system: "theme_system",
  },
  Page_Button: {
    home_page: "home_page",
    log_in_page: "log_in_page",
    join_waitlist_page: "join_waitlist_page",
    sign_up_page: "sign_up_page",
    company_page: "company_page",
    contact_mailto: "contact_mailto",
    terms_page: "terms_page",
    privacy_page: "privacy_page",
    credit_page: "credit_page",
    forgot_password_page: "forgot_password_page",
  },
  Link_Button: {
    official_sns_instagram: "official_sns_instagram",
    official_sns_twitter: "official_sns_twitter",
    official_sns_discord: "official_sns_discord",
  },
  Profile: {
    id_chip: "id_chip",
    personal_colour_palette_colour_chip: "personal_colour_palette_colour_chip",
  },
  Modal: {
    modal_window: "modal_window",
  },
  Vault: {
    upload_vault_object: "upload_vault_object",
    join_vault: "join_vault",
    create_vault: "create_vault",
    save_vault_object: "save_vault_object",
    unsave_vault_object: "unsave_vault_object",
    delete_vault_object: "delete_vault_object",
    route_to_vault: "route_to_vault",
  },
  Notification: {
    open_notification: "open_notification",
  },
} as const;

export const GTM_CUSTOM_TRIGGER_EVENT_NAME = {
  Config: "Config",
  Click: "Click",
  Close: "Close",
  DoubleClick: "Double Click",
  Fetch: "Fetch",
  LongPress: "Long Press",
  Open: "Open",
  Route: "Route",
} as const;

export const GA4_CUSTOM_TRIGGER = {
  Config: {
    Member: (memberUid: string | null) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Config,
      hng: {
        member_uid: memberUid,
      },
    }),
  },
  Click: {
    Preferences_Navigation: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Click,
      hng: {
        eventTarget: GTM_CLASS.Preferences.preferences_navigation,
      },
    }),
    Henge_Object: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Click,
      hng: {
        eventTarget: GTM_CLASS.Henge.henge_object,
      },
    }),
  },
  Close: {
    Modal: (method: "Click X" | "Click Overlay" | "Press Escape") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Close,
      hng: {
        eventTarget: GTM_CLASS.Modal.modal_window,
        method,
      },
    }),
    Rosetta: (method: "App Icon" | "Click X" | "Press Escape") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Close,
      hng: {
        eventTarget: GTM_CLASS.Rosetta.rosetta_window,
        method,
      },
    }),
    Tuning_Shop: (method: "Click X" | "Press Escape") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Close,
      hng: {
        eventTarget: GTM_CLASS.Tuning_Shop.tuning_shop_window,
        method,
      },
    }),
  },
  DoubleClick: {
    ID_Chip: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.DoubleClick,
      hng: {
        eventTarget: GTM_CLASS.Profile.id_chip,
      },
    }),
    Henge_Object: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.DoubleClick,
      hng: {
        eventTarget: GTM_CLASS.Henge.henge_object,
      },
    }),
  },
  Fetch: {
    Log_In: (method: AuthMethod | null, provider: AuthProvider | null) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Auth.log_in,
        method,
        provider,
      },
    }),
    Log_Out: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Auth.log_out,
      },
    }),
    Sign_Up: (method: AuthMethod, provider: AuthProvider) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Auth.sign_up,
        method,
        provider,
      },
    }),
    Waitlist_Closed_Beta: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Auth.waitlist_closed_beta,
      },
    }),
    Gaia_Create: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Gaia.create_gaia,
      },
    }),
    Gaia_Delete: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Gaia.delete_gaia,
      },
    }),
    Gaia_Update_Name: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Gaia.gaia_update_name,
      },
    }),
    Gaia_Update_Emoji: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Gaia.gaia_update_emoji,
      },
    }),
    Henge_Create: (
      method: "Drag & Drop" | "File Dialog" | "Generate" | "Library",
    ) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Henge.create_henge,
        method,
      },
    }),
    Henge_Delete: (method: "Trash Can" | "Button") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Henge.delete_henge,
        method,
      },
    }),
    Henge_Update_Data: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Henge.henge_update_data,
      },
    }),
    Henge_Update_Name: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Henge.henge_update_name,
      },
    }),
    Henge_Update_Transformations: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Henge.henge_update_transformations,
      },
    }),
    Share_Invite_Gaia_Member: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Share.share_invite_gaia_member,
      },
    }),
    Share_Update_Gaia_Member_Role: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Share.share_update_gaia_member_role,
      },
    }),
    Share_Drop_Gaia_Member: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Share.share_drop_gaia_member,
      },
    }),
    Tuning_Shop_Add_Rosetta: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Tuning_Shop.add_rosetta,
      },
    }),
    Tuning_Shop_Remove_Rosetta: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Tuning_Shop.remove_rosetta,
      },
    }),
    Rosetta_Update_Data: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Rosetta.rosetta_update_data,
      },
    }),
    Upload_Vault_Object: (
      vaultUid: string,
      type: VaultObjectType,
      method: "File Dialog" | "Library",
    ) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.upload_vault_object,
        provider: vaultUid,
        type,
        method,
      },
    }),
    Join_Vault: (vaultUid: string) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.join_vault,
        provider: vaultUid,
      },
    }),
    Create_Vault: (access: VaultAccess, uploadPolicy: VaultUploadPolicy) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.create_vault,
        type: access,
        policy: uploadPolicy,
      },
    }),
    Save_Vault_Object: (vaultUid: string, type: VaultObjectType) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.save_vault_object,
        provider: vaultUid,
        type,
      },
    }),
    Unsave_Vault_Object: (vaultUid: string, type: VaultObjectType) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.unsave_vault_object,
        provider: vaultUid,
        type,
      },
    }),
    Delete_Vault_Object: (vaultUid: string, type: VaultObjectType) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Vault.delete_vault_object,
        provider: vaultUid,
        type,
      },
    }),
    Select_Material: (type: "material" | "pbr") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Library.select_material,
        type,
      },
    }),
    Change_Henge_Scope: (scope: HengeShareScope) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Fetch,
      hng: {
        eventTarget: GTM_CLASS.Share.change_henge_scope,
        type: scope,
      },
    }),
  },
  LongPress: {
    Personal_Colour_Palette_Chip: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.LongPress,
      hng: {
        eventTarget: GTM_CLASS.Profile.personal_colour_palette_colour_chip,
      },
    }),
  },
  Open: {
    Preferences: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Open,
      hng: {
        eventTarget: GTM_CLASS.Preferences.open_preferences,
      },
    }),
    Rosetta: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Open,
      hng: {
        eventTarget: GTM_CLASS.Rosetta.rosetta_window,
      },
    }),
    Library: (location: "Vault" | "Gaia" | "Henge") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Open,
      hng: {
        eventTarget: GTM_CLASS.Library.open_library,
        type: location,
      },
    }),
    Share: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Open,
      hng: {
        eventTarget: GTM_CLASS.Share.open_share,
      },
    }),
    Notification: () => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Open,
      hng: {
        eventTarget: GTM_CLASS.Notification.open_notification,
      },
    }),
  },
  Route: {
    Gaia_From_Another_Gaia: (method: "Left Capsule" | "Top Capsule") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Route,
      hng: {
        eventTarget: GTM_CLASS.Gaia.route_to_gaia_from_another_gaia,
        method,
      },
    }),
    Gaia_From_Henge: (method: "Left Capsule" | "Top Capsule") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Route,
      hng: {
        eventTarget: GTM_CLASS.Gaia.route_to_gaia_from_henge,
        method,
      },
    }),
    Gaia_From_Vault: (method: "Top Capsule") => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Route,
      hng: {
        eventTarget: GTM_CLASS.Gaia.route_to_gaia_from_vault,
        method,
      },
    }),
    Go_To_Vault: (
      method: "Top Capsule" | "Library",
      location: "Gaia" | "Henge",
    ) => ({
      event: GTM_CUSTOM_TRIGGER_EVENT_NAME.Route,
      hng: {
        eventTarget: GTM_CLASS.Vault.route_to_vault,
        method,
        type: location,
      },
    }),
  },
} as const;
