import { create } from "zustand";
import { createJWT } from "@/utils/auth/jwt";

export interface OAuthStoreInterface {
  securityToken: string | null;
  sketchfabTokens: {
    accessToken0: string | null;
    accessToken: string | null;
  };
  oauthStoreActions: {
    resetSecurityToken: () => void;
    generateSecurityToken: (payload: string) => Promise<string>;
    setSecurityToken: (securityToken: string | null) => void;
    standbySketchfabAccessToken: (accessToken: string) => void;
    initiateSketchfabAccessToken: () => void;
    terminateSketchfabOAuthStore: () => void;
  };
}

export const SketchfabOAuthStore = create<OAuthStoreInterface>((set) => ({
  securityToken: null,
  sketchfabTokens: {
    accessToken0: null,
    accessToken: null,
  },
  oauthStoreActions: {
    resetSecurityToken: () => {
      set({
        securityToken: null,
      });
    },
    generateSecurityToken: async (payload) => {
      return createJWT(payload);
    },
    setSecurityToken: (securityToken) => {
      set({ securityToken });
    },
    standbySketchfabAccessToken: (accessToken) => {
      set({
        sketchfabTokens: { accessToken0: accessToken, accessToken: null },
      });
    },
    initiateSketchfabAccessToken: () => {
      set((state) => ({
        sketchfabTokens: {
          accessToken0: null,
          accessToken: state.sketchfabTokens.accessToken0,
        },
      }));
    },
    terminateSketchfabOAuthStore: () => {
      set({
        securityToken: null,
        sketchfabTokens: {
          accessToken0: null,
          accessToken: null,
        },
      });
    },
  },
}));
