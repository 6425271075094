import { PopupMessageList } from "@/components/popup/message/PopupMessage";
import HENGE from "@henge-inc/global-types";

export interface ApiResultInterface {
  readonly code: HENGE.ValuesTypeFromObject<typeof Codes>;
  readonly message: string;
  readonly localedMessages: HENGE.ValuesTypeFromObject<typeof LocaledMessages>;
}

class ApiResultImpl implements ApiResultInterface {
  readonly code: HENGE.ValuesTypeFromObject<typeof Codes>;
  readonly message: string;
  readonly localedMessages: HENGE.ValuesTypeFromObject<typeof LocaledMessages>;

  constructor(
    code: HENGE.ValuesTypeFromObject<typeof Codes>,
    message: string,
    localedMessages: HENGE.ValuesTypeFromObject<typeof LocaledMessages>,
  ) {
    this.code = code;
    this.message = message;
    this.localedMessages = localedMessages;
  }
}

const Codes = {
  /**
   * 성공
   */

  OK: "1000",
  ACCEPTED: "1002",

  /**
   * 입력 관련 오류
   */

  ERROR_PARAMETER_VALIDATION: "2000",
  ERROR_PARAMETER: "2001",
  NO_RESERVED_MEMBER: "2002",
  NOT_FOUND_ACCESS_TOKEN: "2003",
  NOT_FOUND_REFRESH_TOKEN: "2004",
  NO_MEMBER: "2005",
  NO_ACCESS_TOKEN_HEADERS: "2006",
  NO_REFRESH_TOKEN_HEADERS: "2007",

  /**
   * 논리 오류
   */

  ERROR_DATA_INTEGRITY: "3000",
  DUPLICATE_EMAIL: "3001",
  DUPLICATE_USERNAME: "3002",
  EXPIRED_MEMBER_VERIFICATION: "3003",
  EXPIRED_JWT: "3004",
  ERROR_PARSE_JWT: "3005",
  ERROR_JWT: "3006",
  INCORRECT_ID_PASSWORD: "3007",
  NOT_VERIFIED_EMAIL: "3008",
  PASSWORD_RESET: "3009",
  EXPIRED_PASSCODE: "3010",
  CONTINUE_SIGNUP_OAUTH: "3011",
  MEMBER_STATUS_MISMATCH: "3012",
  NO_VERIFICATION: "3013",
  ALREADY_VERIFIED_VERIFICATION: "3014",
  BUFFER_DELAY_CREATE_VERIFICATION: "3015",
  NO_PASSCODE: "3016",
  NOT_VERIFIED_PASSCODE: "3017",
  BUFFER_DELAY_CREATE_PASSCODE: "3018",
  NO_CBT_WAITLIST: "3020",
  NOT_APPROVED_CBT_WAITLIST: "3021",
  DUPLICATE_DATA: "3022",
  LIMIT_MIN_GAIA_QUANTITY: "3101",
  LIMIT_MAX_GAIA_QUANTITY: "3102",
  LIMIT_MAX_GAIA_FILE_SIZE: "3104",
  LIMIT_MAX_HENGE_FILE_SIZE: "3201",
  LIMIT_MAX_GAIA_MEMBER_COUNT: "3301",

  /**
   * 시스템 오류
   */

  ERROR_SYSTEM: "4000",
  ERROR_PASSWORD_RESET: "4003",
  UNAUTHORIZED: "4010",

  /**
   * 외부 시스템 오류
   */

  ERROR_OUTER_SYSTEM: "5000",
  ERROR_GOOGLE_MAIL_SEND: "5001",
  ERROR_S3_UPLOAD: "5002",
};

const MsgLocale = {
  KOR: "KOR",
  ENG: "ENG",
} as const;

const LocaledMessages: {
  [key in keyof typeof Codes]: {
    [loc in keyof typeof MsgLocale]: {
      msg: string;
      popupMessageList: PopupMessageList;
    };
  };
} = {
  /**
   * 성공
   */

  OK: {
    KOR: {
      msg: "성공",
      popupMessageList: [],
    },
    ENG: {
      msg: "Successful",
      popupMessageList: [],
    },
  },
  ACCEPTED: {
    KOR: {
      msg: "처리 중",
      popupMessageList: [],
    },
    ENG: {
      msg: "In process",
      popupMessageList: [],
    },
  },

  /**
   * 입력 관련 오류
   */

  ERROR_PARAMETER_VALIDATION: {
    KOR: {
      msg: "파라미터 검증 오류",
      popupMessageList: [],
    },
    ENG: {
      msg: "Invalid parameter",
      popupMessageList: [],
    },
  },
  ERROR_PARAMETER: {
    KOR: {
      msg: "파라미터 오류",
      popupMessageList: [],
    },
    ENG: {
      msg: "Error parameter",
      popupMessageList: [],
    },
  },
  NO_RESERVED_MEMBER: {
    KOR: {
      msg: "등록중인 계정 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "No reserved member",
      popupMessageList: [],
    },
  },
  NOT_FOUND_ACCESS_TOKEN: {
    KOR: {
      msg: "저장된 액세스 토큰 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "Not found access token",
      popupMessageList: [],
    },
  },
  NOT_FOUND_REFRESH_TOKEN: {
    KOR: {
      msg: "저장된 리프레쉬 토큰 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "Not found refresh token",
      popupMessageList: [],
    },
  },
  NO_MEMBER: {
    KOR: {
      msg: "유저 정보 불일치",
      popupMessageList: [{ text: "유저 정보를 확인해 주세요." }],
    },
    ENG: {
      msg: "Incorrect user info",
      popupMessageList: [{ text: "Please check User information." }],
    },
  },
  NO_ACCESS_TOKEN_HEADERS: {
    KOR: {
      msg: "액세스 토큰 헤더 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "No access token headers",
      popupMessageList: [],
    },
  },
  NO_REFRESH_TOKEN_HEADERS: {
    KOR: {
      msg: "리프레쉬 토큰 헤더 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "No refresh token headers",
      popupMessageList: [],
    },
  },

  /**
   * 논리 오류
   */

  ERROR_DATA_INTEGRITY: {
    KOR: {
      msg: "데이터 무결성 오류",
      popupMessageList: [],
    },
    ENG: {
      msg: "Error data integrity",
      popupMessageList: [],
    },
  },
  DUPLICATE_EMAIL: {
    KOR: {
      msg: "이메일 중복",
      popupMessageList: [{ text: "사용 중인 이메일입니다." }],
    },
    ENG: {
      msg: "Email address duplicated",
      popupMessageList: [{ text: "The email address is already in use." }],
    },
  },
  DUPLICATE_USERNAME: {
    KOR: {
      msg: "유저네임 중복",
      popupMessageList: [{ text: "사용 중인 아이디입니다." }],
    },
    ENG: {
      msg: "Username duplicated",
      popupMessageList: [{ text: "The username is already in use." }],
    },
  },
  EXPIRED_MEMBER_VERIFICATION: {
    KOR: {
      msg: "멤버 인증 시간 초과",
      popupMessageList: [{ text: "인증 시간이 초과되었습니다." }],
    },
    ENG: {
      msg: "Verification expired",
      popupMessageList: [{ text: "Member verification has been expired." }],
    },
  },
  EXPIRED_JWT: {
    KOR: {
      msg: "Jwt 기한 만료",
      popupMessageList: [{ text: "세션이 만료되었습니다." }],
    },
    ENG: {
      msg: "Session expired",
      popupMessageList: [{ text: "The session has been expired." }],
    },
  },
  ERROR_PARSE_JWT: {
    KOR: {
      msg: "Jwt 파싱 실패",
      popupMessageList: [],
    },
    ENG: {
      msg: "Error parse jwt",
      popupMessageList: [],
    },
  },
  ERROR_JWT: {
    KOR: {
      msg: "Jwt 에러",
      popupMessageList: [],
    },
    ENG: {
      msg: "Error jwt",
      popupMessageList: [],
    },
  },
  INCORRECT_ID_PASSWORD: {
    KOR: {
      msg: "계정 정보 불일치.",
      popupMessageList: [{ text: "계정 정보를 확인해 주세요." }],
    },
    ENG: {
      msg: "Incorrect account information.",
      popupMessageList: [{ text: "Please check account information." }],
    },
  },
  NOT_VERIFIED_EMAIL: {
    KOR: {
      msg: "이메일 미인증",
      popupMessageList: [{ text: "인증되지 않는 이메일입니다." }],
    },
    ENG: {
      msg: "Not verified email",
      popupMessageList: [{ text: "The email is not verified." }],
    },
  },
  PASSWORD_RESET: {
    KOR: {
      msg: "패스워드 초기화 중",
      popupMessageList: [],
    },
    ENG: {
      msg: "Reset password in progress",
      popupMessageList: [],
    },
  },
  EXPIRED_PASSCODE: {
    KOR: {
      msg: "패스코드 기한 만료",
      popupMessageList: [{ text: "패스코드가 만료되었습니다." }],
    },
    ENG: {
      msg: "Passcode expired",
      popupMessageList: [{ text: "The passcode has been expired." }],
    },
  },
  CONTINUE_SIGNUP_OAUTH: {
    KOR: {
      msg: "OAuth 회원가입 진행",
      popupMessageList: [],
    },
    ENG: {
      msg: "Continue signup with OAuth",
      popupMessageList: [],
    },
  },
  MEMBER_STATUS_MISMATCH: {
    KOR: {
      msg: "멤버 상태 불일치.",
      popupMessageList: [
        { text: "계정에 문제가 발생했습니다." },
        { text: "문의해 주시기 바랍니다." },
      ],
    },
    ENG: {
      msg: "Member status does not match.",
      popupMessageList: [
        { text: "Something wrong with this account." },
        { text: "Contact to hello@henge.io." },
      ],
    },
  },
  NO_VERIFICATION: {
    KOR: {
      msg: "인증 정보 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "Verification not found",
      popupMessageList: [],
    },
  },
  ALREADY_VERIFIED_VERIFICATION: {
    KOR: {
      msg: "이미 처리된 인증",
      popupMessageList: [],
    },
    ENG: {
      msg: "Already verified Verification",
      popupMessageList: [],
    },
  },
  BUFFER_DELAY_CREATE_VERIFICATION: {
    KOR: {
      msg: "인증 생성 딜레이",
      popupMessageList: [
        {
          text: "잠시 후 다시 시도해 주세요.",
        },
      ],
    },
    ENG: {
      msg: "Delay for a new Verification",
      popupMessageList: [
        {
          text: "Please retry in a seconds.",
        },
      ],
    },
  },
  NO_PASSCODE: {
    KOR: {
      msg: "패스코드 정보 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "Passcode not found",
      popupMessageList: [],
    },
  },
  NOT_VERIFIED_PASSCODE: {
    KOR: {
      msg: "인증되지 않은 패스코드",
      popupMessageList: [],
    },
    ENG: {
      msg: "Passcode is not verified",
      popupMessageList: [],
    },
  },
  BUFFER_DELAY_CREATE_PASSCODE: {
    KOR: {
      msg: "패스코드 생성 딜레이",
      popupMessageList: [],
    },
    ENG: {
      msg: "Delay for a new Passcode",
      popupMessageList: [],
    },
  },
  NO_CBT_WAITLIST: {
    KOR: {
      msg: "클로즈베타 대기자 명단 없음",
      popupMessageList: [],
    },
    ENG: {
      msg: "Not found on CBT waitlist",
      popupMessageList: [],
    },
  },
  NOT_APPROVED_CBT_WAITLIST: {
    KOR: {
      msg: "허용되지 않은 클로즈베타 대기자",
      popupMessageList: [],
    },
    ENG: {
      msg: "Not approved CBT waitlist",
      popupMessageList: [],
    },
  },
  DUPLICATE_DATA: {
    KOR: {
      msg: "중복 데이터",
      popupMessageList: [],
    },
    ENG: {
      msg: "duplicate data",
      popupMessageList: [],
    },
  },
  LIMIT_MIN_GAIA_QUANTITY: {
    KOR: {
      msg: "가이아 최소 개수 미달",
      popupMessageList: [
        { text: "마지막 한 개의 가이아는 삭제할 수 없습니다." },
      ],
    },
    ENG: {
      msg: "Minimum Gaia quantity limitation",
      popupMessageList: [{ text: "Cannot delete the last only Gaia." }],
    },
  },
  LIMIT_MAX_GAIA_QUANTITY: {
    KOR: {
      msg: "가이아 최대 개수 초과",
      popupMessageList: [
        { text: "가이아 개수가 한도에 도달했습니다." },
        { text: "더 이상 가이아를 생성할 수 없습니다." },
      ],
    },
    ENG: {
      msg: "Maximum Gaia quantity limitation",
      popupMessageList: [
        { text: "Gaia quantity exceeded the maximum limitation." },
        { text: "Cannot create Gaia anymore." },
      ],
    },
  },
  LIMIT_MAX_GAIA_FILE_SIZE: {
    KOR: {
      msg: "가이아 최대 용량 초과",
      popupMessageList: [{ text: "가이아 용량이 한도에 도달했습니다." }],
    },
    ENG: {
      msg: "Maximum Gaia file size limitation",
      popupMessageList: [
        { text: "Gaia file size exceeded the maximum limitation." },
      ],
    },
  },
  LIMIT_MAX_HENGE_FILE_SIZE: {
    KOR: {
      msg: "헨지 최대 용량 초과",
      popupMessageList: [
        { text: "업로드 가능한 단일 헨지 최대 용량을 초과했습니다." },
      ],
    },
    ENG: {
      msg: "Maximum Henge file size limitation",
      popupMessageList: [
        {
          text: "Upload size for a single Henge exceeded the maximum limitation.",
        },
      ],
    },
  },
  LIMIT_MAX_GAIA_MEMBER_COUNT: {
    KOR: {
      msg: "가이아 멤버 최대 인원 초과",
      popupMessageList: [
        { text: "해당 가이아에 초대 가능한 멤버 수가 한도에 도달했습니다." },
        { text: "더 이상 멤버를 초대할 수 없습니다." },
      ],
    },
    ENG: {
      msg: "Maximum Gaia member limitation",
      popupMessageList: [
        {
          text: "The Gaia's member exceeded the maximum limitation.",
        },
        {
          text: "Cannot invite anymore member to this Gaia.",
        },
      ],
    },
  },

  /**
   * 시스템 오류
   */

  ERROR_SYSTEM: {
    KOR: {
      msg: "헨지 서비스 이용이 불가능합니다",
      popupMessageList: [
        { text: "문제가 발생했습니다." },
        { text: "잠시 후 다시 시도해 주세요." },
      ],
    },
    ENG: {
      msg: "Henge service err",
      popupMessageList: [
        {
          text: "Something went wrong.",
        },
        {
          text: "Please try again in few minutes.",
        },
      ],
    },
  },
  ERROR_PASSWORD_RESET: {
    KOR: {
      msg: "패스워드 초기화 실패",
      popupMessageList: [{ text: "비밀번호 초기화에 실패했습니다." }],
    },
    ENG: {
      msg: "Error password reset",
      popupMessageList: [
        {
          text: "Reset password failed.",
        },
      ],
    },
  },
  UNAUTHORIZED: {
    KOR: {
      msg: "인증 실패",
      popupMessageList: [{ text: "허가되지 않은 요청입니다." }],
    },
    ENG: {
      msg: "Unauthorized",
      popupMessageList: [
        {
          text: "Not allowed request.",
        },
      ],
    },
  },

  /**
   * 외부 시스템 오류
   */

  ERROR_OUTER_SYSTEM: {
    KOR: {
      msg: "외부 시스템 오류",
      popupMessageList: [
        { text: "문제가 발생했습니다." },
        { text: "잠시 후 다시 시도해 주세요." },
      ],
    },
    ENG: {
      msg: "Error password reset",
      popupMessageList: [
        {
          text: "External service err",
        },
        {
          text: "Please try again in few minutes.",
        },
      ],
    },
  },
  ERROR_GOOGLE_MAIL_SEND: {
    KOR: {
      msg: "구글 메일 시스템 오류",
      popupMessageList: [
        { text: "문제가 발생했습니다." },
        { text: "잠시 후 다시 시도해 주세요." },
      ],
    },
    ENG: {
      msg: "Failed sending Google email",
      popupMessageList: [
        {
          text: "External service err",
        },
        {
          text: "Please try again in few minutes.",
        },
      ],
    },
  },
  ERROR_S3_UPLOAD: {
    KOR: {
      msg: "파일 시스템 오류",
      popupMessageList: [
        { text: "문제가 발생했습니다." },
        { text: "잠시 후 다시 시도해 주세요." },
      ],
    },
    ENG: {
      msg: "Failed uploading file",
      popupMessageList: [
        {
          text: "External service err",
        },
        {
          text: "Please try again in few minutes.",
        },
      ],
    },
  },
};

type ApiResultsType = { [K in keyof typeof Codes]: ApiResultImpl };
const ApiResults: ApiResultsType = Object.keys(Codes).reduce(
  (acc, key) => {
    acc[key as keyof typeof Codes] = new ApiResultImpl(
      Codes[key as keyof typeof Codes],
      "",
      LocaledMessages[key as keyof typeof Codes],
    );

    return acc;
  },
  {} as { [K in keyof typeof Codes]: ApiResultImpl },
);

interface ApiResultEnumInterface extends ApiResultsType {
  fromCode: (code: string) => ApiResultImpl | undefined;
}

export const ApiResultEnum: ApiResultEnumInterface = {
  ...ApiResults,
  fromCode(code: string) {
    return Object.values(ApiResults).find((impl) => impl.code === code);
  },
};
