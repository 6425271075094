import { PopupPortal } from "@/components/popup/PopupPortal";
import { css } from "@emotion/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export interface PopupLayoutHandleProps {
  handle: boolean;
  resetHandler: (handle: boolean) => void;
}

export interface PopupLayoutProps extends PopupLayoutHandleProps {
  firstShowingSeconds?: number;
  hoverShowingSeconds?: number;
  showOnce?: boolean;
  filled?: boolean;
}

export const PopupLayout = ({
  children,
  handle,
  resetHandler,
  firstShowingSeconds = 2,
  hoverShowingSeconds = 0.5,
  showOnce = true,
  filled = true,
}: PopupLayoutProps & { children?: React.ReactNode }) => {
  const resetTimer = useRef<NodeJS.Timeout>();
  const [resetByTimer, setResetByTimer] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [firstShow, setFirstShow] = useState<boolean>(false);
  const showOnceTimer = useRef<NodeJS.Timeout>();
  const [hover, setHover] = useState<boolean>(false);

  useEffect(() => {
    clearTimeout(resetTimer.current);

    if (handle) {
      setResetByTimer(false);
      setShow(true);
      setFirstShow(true);

      resetTimer.current = setTimeout(() => {
        setResetByTimer(true);
        resetHandler(false);
      }, firstShowingSeconds * 1000);
    } else {
      if (resetByTimer) {
        if (showOnce) {
          setShow(false);
        }
        setFirstShow(false);
      } else {
        setShow(false);
        setFirstShow(false);
      }
    }

    return () => {
      clearTimeout(resetTimer.current);
      clearTimeout(showOnceTimer.current);
    };
  }, [handle]);

  return (
    <PopupPortal>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{
              transform: "translate(-50%, calc(16px - 100%))",
            }}
            animate={{
              transform: "translate(-50%, 0)",
            }}
            exit={{
              transform: "translate(-50%, calc(16px - 100%))",
            }}
            css={css({
              userSelect: "none",
              pointerEvents: "auto",
              position: "fixed",
              top: 0,
              left: "50%",
              transform: firstShow
                ? ""
                : `translate(-50%, ${
                    hover ? 0 : "calc(16px - 100%)"
                  }) !important`,
              padding: "16px",
              transition: "transform 0.5s var(--ease-in-out)",
            })}
            onMouseEnter={() => {
              if (!showOnce) {
                resetHandler(true);
                setResetByTimer(false);
                setHover(true);
                clearTimeout(showOnceTimer.current);
              }
            }}
            onMouseLeave={() => {
              if (!showOnce) {
                clearTimeout(showOnceTimer.current);

                showOnceTimer.current = setTimeout(() => {
                  setHover(false);
                }, hoverShowingSeconds * 1000);
              }
            }}
          >
            <div
              className={`${filled && "bg-[var(--black)]"} flex flex-col flex-nowrap px-4 py-2 justify-center items-center rounded-2xl`}
              style={{
                boxShadow: filled ? "" : undefined,
                border: filled ? "" : undefined,
              }}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </PopupPortal>
  );
};
