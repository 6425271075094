export const GaiaAck = {
  JOIN_MEMBER: "JOIN_MEMBER",
  LEAVE_MEMBER: "LEAVE_MEMBER",
  UPDATE_MOUSE: "UPDATE_MOUSE",
  REFRESH_GAIA: "REFRESH_GAIA",
  REFRESH_HENGE: "REFRESH_HENGE",
};

export const MemberAck = {
  INITIATED: "INITIATED",
  REFRESH_NOTIFICATION: "REFRESH_NOTIFICATION",
};
